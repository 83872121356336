import { config } from "@/Helper/config";
import axios from "axios";


const getAllUtilityData = () => {
  return axios.get(`${config.strapiAPi}/api/utility?populate=*`);
};

const getAllSponsorsImage = () => {
  return axios.get(`${config.strapiAPi}/api/sponsor?populate=sponsor_image`);
};

const getAllCustomerVoiceDetails = () => {
  return axios.get(`${config.strapiAPi}/api/customers-voices?populate=*`);
};

const getAllFAQ = () => {
  return axios.get(`${config.strapiAPi}/api/faqs`);
};

const getFAQByCategory = ({ page }) => {
  return axios.get(`${config.strapiAPi}/api/getfaq/${page}`);
};

const getRecentBlog = (currentPage = null) => {
  const pagination =
    currentPage && currentPage >= 1
      ? `&pagination[page]=${currentPage}&pagination[pageSize]=12`
      : '';
  return axios.get(
    `${config.strapiAPi}/api/blogs?populate=image&sort=publishedAt:desc${pagination}`
  );
};

const getRecentBlogWithkeySearch = ({ keyword = "-" }) => {
  return axios.get(`${config.strapiAPi}/api/bloggers/${keyword}`);
};

const getBlogDetailsById = ({ id }) => {
  return axios.get(`${config.strapiAPi}/api/blogger/${id}`, {
    rejectUnauthorized: false,
    strictSSL: false,
  });
};

const getBlogDetailById = ({ id }) => {
  return axios.get(`${config.strapiAPi}/api/blogs/${id}`);
};

const postContactUsDetails = ({ data }) => {
  return axios.post(`${config.strapiAPi}/api/contact-uses`, { data });
};

const sendThankyouMail = ({ data }) => {
  return axios.post(`${config.strapiAPi}/api/email`, data);
};

const getPillarPageData = () => {
  return axios.get(`${config.strapiAPi}/api/digital-signage`);
};

const getDesklessPillarPageData = () => {
  return axios.get(`${config.strapiAPi}/api/bb-pillar-page`);
};


export const apiService = {
  getAllUtilityData,
  getAllSponsorsImage,
  getAllCustomerVoiceDetails,
  getAllFAQ,
  getRecentBlog,
  getBlogDetailsById,
  postContactUsDetails,
  sendThankyouMail,
  getFAQByCategory,
  getBlogDetailById,
  getPillarPageData,
  getDesklessPillarPageData,
  getRecentBlogWithkeySearch,
  
};
