import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const DemoPopup = ({ open = false, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton onClick={onClose} className="demo-close-btn">
          <CancelIcon sx={{ color: "#226DB4", fontSize: 35 }} />
        </IconButton>

        
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        ></script>
      </Box>
    </Modal>
  );
};

export default DemoPopup;
