/** @format */

"use client";
import "./NavBar.css";
import React, { useEffect, useState, useRef } from "react";
import Link from "next/link";
import Image from "next/image";


import { usePathname } from "next/navigation";
import { Snackbar } from "@mui/material";
import { apiService } from "@/services/api.services";
import BurgerMenu from "./BurgerMenu";

const NavBar = () => {
  const pathname = usePathname();
  const [isOnScroll, setIsOnScroll] = useState(false);
  const [logo, setLogo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const dropdownRef = useRef(null); // Ref for dropdown menu

  useEffect(() => {
    apiService.getAllUtilityData().then((resp) => {
      if (resp.status === 200) {
        setLogo(`${resp.data?.data?.attributes?.logo?.data?.attributes?.url}`);
      }
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setIsOnScroll(true);
      } else {
        setIsOnScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const ResourcesDropdown = () => (
    <ul className="nav_subulweb nav_subul ResourcesDropdown">
      <div>
        <li>
          <Link
            href={"/robotics-process-automation"}
            key={"Robotic Process Automation"}
            aria-label={`Robotic Process Automation`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  "/robotics-process-automation" !== "/"
                    ? pathname.includes(
                        "/robotics-process-automation"
                      )
                      ? "currentPage"
                      : ""
                    : pathname === "/robotics-process-automation"
                    ? "currentPage"
                    : ""
                }
              >
                Robotic Process Automation
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/vr-application"}
            key={"vr-application"}
            aria-label={`vr-application`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  "/vr-application" !== "/"
                    ? pathname.includes(
                        "/vr-application"
                      )
                      ? "currentPage"
                      : ""
                    : pathname === "/vr-application"
                    ? "currentPage"
                    : ""
                }
              >
              VR Applications
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/health-monitors"}
            key={"health-monitors"}
            aria-label={`Health Monitors`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  "/health-monitors" !== "/"
                    ? pathname.includes(
                        "/health-monitors"
                      )
                      ? "currentPage"
                      : ""
                    : pathname === "/health-monitors"
                    ? "currentPage"
                    : ""
                }
              >
             Health Monitors
              </span>
            </p>
          </Link>
        </li>
        
       
       
       
       
      </div>
      <div></div>
    </ul>
  );

  return (
    <div className={`nav_area ${isOnScroll ? "onscroll_bg" : ""}`}>
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={() => setIsOpen(false)}
        message="Coming Soon!"
      />
      <div className="container">
        <nav className="nav_container">
          <div className="nav_container_left">
            {logo && (
              <Link href={"/"} key={"logo"}>
                <Image
                  src={logo}
                  alt="logo"
                  width={165}
                  height={53}
                  className="nav_logo"
                />
              </Link>
            )}
          </div>
          <div className="nav_container_right">
            <div className="nav_container_right_inner nav_container_right_innermenu">
              <NavLink href="/" label="Home" pathname={pathname} />
              <div className="navbar_desktop withsubmenunav">
                <span
                  className={`mainspan ${
                    pathname.includes(
                      "/robotics-process-automation"
                    ) ||
                    pathname.includes(
                      "/vr-application"
                    ) ||
                    pathname.includes("/health-monitors") 
                      ? "currentPage"
                      : ""
                  }`}
                >
                  Products <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.75592 8.09225C4.43048 8.41767 4.43048 8.94525 4.75592 9.27075L8.83277 13.3436C9.48377 13.9939 10.5386 13.9937 11.1892 13.343L15.2645 9.26775C15.5899 8.94225 15.5899 8.41467 15.2645 8.08917C14.9391 7.76375 14.4114 7.76375 14.086 8.08917L10.5979 11.5773C10.2725 11.9027 9.74494 11.9027 9.41944 11.5773L5.93443 8.09225C5.609 7.76675 5.08136 7.76675 4.75592 8.09225Z" fill="currentColor"></path>
          </svg>
                </span>
                <ResourcesDropdown />
              </div>
              <NavLink href="/about-us" label="About us" pathname={pathname} />
              {/* <NavLink href="/blog" label="Blog" pathname={pathname} isBlog /> */}
            </div>

            <div className="nav_container_right_inner nav_container_right_innerbtn">
              <Link target="_blank" href={"/contact-us"} className="cta">
                Contact us
                <span>
                  <svg
                    width="13"
                    height="11"
                    viewBox="0 0 13 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.89388 8.82547C7.82326 8.89127 7.76662 8.97062 7.72734 9.05879C7.68805 9.14696 7.66693 9.24213 7.66523 9.33864C7.66352 9.43515 7.68128 9.53101 7.71743 9.6205C7.75358 9.71 7.80738 9.7913 7.87563 9.85955C7.94388 9.9278 8.02518 9.98161 8.11468 10.0178C8.20418 10.0539 8.30004 10.0717 8.39654 10.07C8.49305 10.0683 8.58823 10.0471 8.67639 10.0078C8.76456 9.96856 8.84391 9.91192 8.90971 9.84131L12.743 6.00797C12.8099 5.94137 12.863 5.86222 12.8992 5.77506C12.9354 5.68789 12.954 5.59444 12.954 5.50006C12.954 5.40567 12.9354 5.31222 12.8992 5.22506C12.863 5.1379 12.8099 5.05874 12.743 4.99214L8.90971 1.15881C8.84391 1.08819 8.76456 1.03155 8.67639 0.992266C8.58823 0.952983 8.49305 0.93186 8.39654 0.930157C8.30004 0.928454 8.20418 0.946207 8.11468 0.982356C8.02518 1.0185 7.94388 1.07231 7.87563 1.14056C7.80738 1.20881 7.75358 1.29011 7.71743 1.37961C7.68128 1.46911 7.66352 1.56497 7.66523 1.66147C7.66693 1.75798 7.68805 1.85316 7.72734 1.94132C7.76662 2.02949 7.82326 2.10884 7.89388 2.17464L10.5005 4.78131L1.21429 4.78131C1.02367 4.78131 0.840852 4.85703 0.706061 4.99182C0.571269 5.12661 0.495544 5.30943 0.495544 5.50006C0.495544 5.69068 0.571268 5.8735 0.706061 6.00829C0.840852 6.14308 1.02367 6.21881 1.21429 6.21881L10.5005 6.21881L7.89388 8.82547Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </nav>
        <BurgerMenu pathname={pathname} />
      </div>
    </div>
  );
};

const NavLink = ({ href, label, pathname, isBlog }) => (
  <Link href={href} key={label} aria-label={label.toLowerCase()}>
    <p className="navbar_desktop">
      <span className={pathname === href || (isBlog && pathname.startsWith("/blog")) ? "currentPage" : ""}>
        {label}
      </span>
    </p>
  </Link>
);

export default NavBar;
