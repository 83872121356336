/** @format */

"use client";
import Link from "next/link";
import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DemoButton from "../Demo/DemoButton";

const BurgerMenu = ({ pathname = "/" }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const [isSubmenuVisible, setSubmenuVisible] = useState(false);
  const toggleSubmenu = () => {
    setSubmenuVisible(!isSubmenuVisible);
  };

  const ResourcesDropdown = () => (
    <ul
      className={`nav_subul mobile_subnav ${
        isSubmenuVisible ? "showSubmenu" : "hidesubmenu"
      }`}
    >
      <li>
        <Link
          href={"/robotics-process-automation"}
          key={"robotics-process-automation"}
          aria-label={`robotics-process-automation`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/robotics-process-automation" !== "/"
                  ? pathname.includes("/robotics-process-automation")
                    ? "currentPage"
                    : ""
                  : pathname === "/robotics-process-automation"
                  ? "currentPage"
                  : ""
              }
            >
              Robotic Process Automation
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/vr-application"}
          key={"vr-application"}
          aria-label={`vr-application`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/vr-application" !== "/"
                  ? pathname.includes("/vr-application")
                    ? "currentPage"
                    : ""
                  : pathname === "/vr-application"
                  ? "currentPage"
                  : ""
              }
            >
              VR Applications
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/health-monitors"}
          key={"health-monitors"}
          aria-label={`health-monitors`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/health-monitors" !== "/"
                  ? pathname.includes("/health-monitors")
                    ? "currentPage"
                    : ""
                  : pathname === "/health-monitors"
                  ? "currentPage"
                  : ""
              }
            >
              Health Monitors
            </span>
          </p>
        </Link>
      </li>
    </ul>
  );

  return (
    <Menu
      left
      isOpen={isMenuOpen}
      onStateChange={handleStateChange}
      customCrossIcon={<CloseIcon sx={{ color: "#226DB4" }} />}
      customBurgerIcon={<MenuIcon sx={{ color: "#226DB4" }} />}
    >
      <div className="nav_container_right">
        <Link
          href={"/"}
          key={"home"}
          onClick={() => {
            setMenuOpen(false);
          }}
          aria-label={`home`}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/" !== "/"
                  ? pathname.includes("/")
                    ? "currentPage"
                    : ""
                  : pathname === "/"
                  ? "currentPage"
                  : ""
              }
            >
              Home
            </span>
          </p>
        </Link>
        
        <div
          className={`navbar_mobile withsubmenunav ${
            isSubmenuVisible ? "showSubmenu" : ""
          }`}
        >
          <span
            onClick={toggleSubmenu}
            className={`mainspan ${
              pathname.includes("/robotics-process-automation") ||
              pathname.includes("/vr-application") ||
              pathname.includes("/health-monitors")
                ? "currentPage"
                : ""
            }`}
          >
            Products  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.75592 8.09225C4.43048 8.41767 4.43048 8.94525 4.75592 9.27075L8.83277 13.3436C9.48377 13.9939 10.5386 13.9937 11.1892 13.343L15.2645 9.26775C15.5899 8.94225 15.5899 8.41467 15.2645 8.08917C14.9391 7.76375 14.4114 7.76375 14.086 8.08917L10.5979 11.5773C10.2725 11.9027 9.74494 11.9027 9.41944 11.5773L5.93443 8.09225C5.609 7.76675 5.08136 7.76675 4.75592 8.09225Z" fill="currentColor"></path>
          </svg>
          </span>
          {ResourcesDropdown()}
        </div>
        {/* <div
          className={`navbar_mobile withsubmenunav ${
            isSubmenuVisible ? "showSubmenu" : "dddd"
          }`}
        >
          <span
            onClick={toggleSubmenu}
            className={`mainspan ${
              pathname.includes("/digital-signage-software-for-hospitals") ||
              pathname.includes("/digital-signage-software-for-education") ||
              pathname.includes("/digital-signage-software-for-qsr") ||
              pathname.includes("/digital-signage-software-for-fitness") ||
              pathname.includes(
                "/digital-signage-for-manufacturing-industry"
              ) ||
              pathname.includes("/retail") ||
              pathname.includes("/digital-signage-software-for-corporate") ||
              pathname.includes(
                "/digital-signage-software-for-transportation-supply-chain"
              )
                ? "currentPage"
                : ""
            }`}
          >
            Industries
          </span>
          <ResourcesDropdown />
        </div> */}
        {/* <Link
          href={"/features"}
          key={"features"}
          onClick={() => {
            setMenuOpen(false);
          }}
          aria-label={`features`}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/features" !== "/"
                  ? pathname.includes("/features")
                    ? "currentPage"
                    : ""
                  : pathname === "/features"
                  ? "currentPage"
                  : ""
              }
            >
              Features
            </span>
          </p>
        </Link> */}
        {/* <Link
          href={"/blog"}
          key={"blog"}
          onClick={() => {
            setMenuOpen(false);
          }}
          aria-label={`blog`}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/blog" !== "/"
                  ? pathname.includes("/blog")
                    ? "currentPage"
                    : ""
                  : pathname === "/blog"
                  ? "currentPage"
                  : ""
              }
            >
              Blog
            </span>
          </p>
        </Link> */}

        <Link
          href={"/contact-us"}
          key={"contact-us"}
          onClick={() => {
            setMenuOpen(false);
          }}
          aria-label={`contact-us`}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/contact-us" !== "/"
                  ? pathname.includes("/contact-us")
                    ? "currentPage"
                    : ""
                  : pathname === "/contact-us"
                  ? "currentPage"
                  : ""
              }
            >
              Contact Us
            </span>
          </p>
        </Link>

        {/* <DemoButton navBar aria-hidden="true" /> */}
      </div>
    </Menu>
  );
};

export default BurgerMenu;
